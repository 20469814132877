import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { NavItem } from '../../utils/types';

import Button from '../../components/button';
import './pollen.css';

export interface LoyaltyProps {
  number?: string;
  headerText?: string;
  headerTextMain?: string;
  style?: React.CSSProperties;
  navItems?: Array<NavItem>;
  onNavItemSelected?: (index: number) => void;
}

const RiseNShine: React.FC<LoyaltyProps> = ({
  number,
  headerText = 'SPECIAL PRICING',
  headerTextMain = 'Get A Month Free!',
  style,
  navItems,
  onNavItemSelected
}) => {
  const images = useStaticQuery(graphql`
    query {
      offer: file(relativePath: { eq: "rns-prices-offer.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      textImage: file(relativePath: { eq: "text_rise-n-shine.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const offerImage = images.offer.childImageSharp.fluid;
  const riseNShineImage = images.textImage.childImageSharp.fluid;

  return (
    <div id="Loyalty" style={style}>
      <div className="Loyalty-content">
        <div className="Loyalty-inner-content">
          <div className="Loyalty-title">
            <div className="Loyalty-title__content">
              <h3 className="Loyalty-title__content-top">{headerText}</h3>
              <Img
                fluid={riseNShineImage}
                imgStyle={{ objectFit: 'contain' }}
                style={{ width: '440px', margin: '16px 0 24px' }}
              />
            </div>
          </div>
          <div className="Loyalty-details">
            Start your morning fresh and clean with Rise N’ Shine savings at
            Fins Car Wash. Every day FINS is up with the sun offering daily
            discounts. Score a Top Wash for just $15 or cruise in for our
            Standard Wash for just $11 from 7 a.m.– 9a.m. Enjoy free vacuums and
            mat cleaners with every wash.
            <br />
            <br />
            <a href="https://finswash.com/#Location">
              <Button text="VIEW FINS LOCATIONS" />
            </a>
          </div>
        </div>
        <div className="Loyalty-media">
          <Img
            fluid={offerImage}
            imgStyle={{ objectFit: 'contain' }}
            className="Loyalty-media__image"
            style={{ maxWidth: '444px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default RiseNShine;
